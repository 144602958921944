@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
@import "~react-image-gallery/styles/css/image-gallery.css";
@import "~react-multi-carousel/lib/styles.css";
@import "./ckeditor-styles.css";

body {
    font-family: Inter;
    font-style: normal;

    min-width: 360px;
    margin: unset;
}

a {
    text-decoration: none;
    cursor: pointer;
}

a:hover {
    cursor: pointer;
}

/*!***/
/* * The CSS shown here will not be introduced in the Quickstart guide, but shows*/
/* * how you can use CSS to style your Element's container.*/
/* *!*/
/*.StripeElement {*/
/*    box-sizing: border-box;*/
/*    padding: 10px 12px;*/

/*    border: 1px solid transparent;*/
/*    border-radius: 4px;*/
/*    background-color: white;*/

/*    box-shadow: 0 1px 3px 0 #e6ebf1;*/
/*    -webkit-transition: box-shadow 150ms ease;*/
/*    transition: box-shadow 150ms ease;*/
/*}*/

/*.StripeElement--focus {*/
/*    box-shadow: 0 1px 3px 0 #cfd7df;*/
/*}*/

/*.StripeElement--invalid {*/
/*    border-color: #fa755a;*/
/*}*/

/*.StripeElement--webkit-autofill {*/
/*    background-color: #fefde5 !important;*/
/*}*/
